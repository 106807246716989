import { BaseLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import {jwtDecode} from "jwt-decode";

declare let AppleID: any;

export class AppleLoginProvider extends BaseLoginProvider {

  public static readonly PROVIDER_ID: string = 'APPLE';

  protected auth2: any;

  constructor(private clientId: string, private scope: string, private redirectURI: string) { super(); }

  initialize(): Promise<void> {
    return new Promise((resolve) => {
      this.loadScript(AppleLoginProvider.PROVIDER_ID,
        'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
        () => {
          this.auth2 = AppleID.auth.init({
            clientId : this.clientId,
            scope : this.scope,
            redirectURI: this.redirectURI,
            usePopup : true
          });
          resolve()
        });
    });
  }

  // @ts-ignore
  signIn(opt?: object): Promise<SocialUser> {
    // @ts-ignore
    return new Promise(async (resolve, reject) => {
      const {
        authorization: { code, id_token },
        user,
      } = await AppleID.auth.signIn()

      const decoded = jwtDecode<any>(id_token);

      const socialUser = new SocialUser();
      socialUser.id = decoded.sub!!;
      socialUser.firstName = user?.name.firstName;
      socialUser.lastName = user?.name.lastName;
      socialUser.email = decoded.email
      socialUser.authorizationCode = code;
      socialUser.idToken = id_token;
      socialUser.provider = AppleLoginProvider.PROVIDER_ID;
      socialUser.response = decoded;

      resolve(socialUser);
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    // @ts-ignore
    return new Promise((resolve, reject) => {
      // @ts-ignore
        resolve();
    })
  }

  signOut(): Promise<any> {
    return new Promise((resolve) => {
      // AppleID doesnt have revoke method
      // @ts-ignore
      resolve();
    });
  }
}
