import { EnvironmentProviders, Provider } from '@angular/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { environment } from "../../environments/environment";
import {AppleLoginProvider} from "./social/apple.provider";

export const provideSocialAuth = (): Array<Provider | EnvironmentProviders> => {
  return [
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: () => {
        return {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider(
                environment.google_app_id
              )
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider(
                environment.facebook_app_id
              )
            },
            {
              id: AppleLoginProvider.PROVIDER_ID,
              provider: new AppleLoginProvider(
                environment.apple_app_id,
                "name email",
                environment.site_url + "/api/v2/auth/apple"
              )
            }
          ],
          onError: (err) => {
            console.error(err);
          }
        } as SocialAuthServiceConfig
      }
    },
  ]
}
export const provideAuth = (): Array<Provider | EnvironmentProviders> => {
  return [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    // { provide: HTTP_INTERCEPTORS, useClass: AccessTokenInterceptor, multi: true },
    provideSocialAuth()
  ];
};
